const PermitHolderService = {
    
    fieldsToUpdate(permit) {
        let fields = [];

        if(permit.hasOwnProperty('name')) {
            if(permit.name == null || permit.name.trim() == '') {
                fields.push('name');
            }
        }

        if(permit.hasOwnProperty('address')) {
            if(permit.address == null || permit.address.trim() == '') {
                fields.push('address');
            }
        }

        if(permit.hasOwnProperty('town')) {
            if(permit.town == null || permit.town.trim() == '') {
                fields.push('town');
            }
        }

        if(permit.hasOwnProperty('postal_code')) {
            if(permit.postal_code == null || permit.postal_code.trim() == '') {
                fields.push('postal_code');
            }
        }

        if(permit.hasOwnProperty('residence_type')) {
            if(permit.residence_type == null ) {
                fields.push('residence_type');
            }
        }

        if(permit.hasOwnProperty('entity_type')) {
            if(permit.entity_type == null ) {
                fields.push('entity_type');
            }
        }

        if(permit.hasOwnProperty('tax_number')) {
            if(permit.tax_number == null || permit.tax_number.trim() == '') {
                fields.push('tax_number');
            }
        }

        return fields;
    }
}

export default PermitHolderService