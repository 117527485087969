<template>
    <div>
        <v-form id="form_parking_application" ref="form" v-model="valid" lazy-validation>
            <v-layout row wrap align-center justify-center fill-height>
                <v-flex xs12 sm12 md10 lg10 xl8>
                    <v-card id="form_card">
                        <v-img
                        :src="imgParking"
                        height="300px"
                        ></v-img>
                        <v-card-title>
                            <span class="subtitle-1 application--card--title text-center">
                            <v-icon left>mdi-file-document-edit-outline</v-icon>
                            Vloga za podaljšanje parkirne dovolilnice št. # {{ permitId }}</span>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <!-- backend validation messages -->
                            <form-validation-messages :messages="apiValidationMessages">
                            </form-validation-messages>

                            <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>
                            
                            <info-user-type-domacin ></info-user-type-domacin>
                            <info-user-type-gost ></info-user-type-gost>
                            <info-user-type-velika-zaka></info-user-type-velika-zaka>
                            

                            <!-- tip uporabnika -->
                            <v-select
                            :items="userTypes"
                            label="Tip dovolilnice"
                            item-text="name"
                            item-value="id"
                            v-model="user_type"
                            :rules="user_type_rules"
                            filled
                            readonly
                            ></v-select>

                            <!-- imetnik -->
                            <v-text-field
                                v-model="name"
                                label="Ime in priimek"
                                required
                                :rules="name_rules"
                                :filled="!existing_permit_holder_fields_to_update.includes('name')"
                                :readonly="!existing_permit_holder_fields_to_update.includes('name')"
                            ></v-text-field>

                            <!-- ulica -->
                            <v-text-field
                                v-model="address"
                                label="Naslov (prebivališče)"
                                required
                                :rules="address_rules"
                                :filled="!existing_permit_holder_fields_to_update.includes('address')"
                                :readonly="!existing_permit_holder_fields_to_update.includes('address')"
                            ></v-text-field>

                            <!-- kraj -->
                            <v-text-field
                                v-model="town"
                                label="Kraj"
                                required
                                :rules="town_rules"
                                :filled="!existing_permit_holder_fields_to_update.includes('town')"
                                :readonly="!existing_permit_holder_fields_to_update.includes('town')"
                            ></v-text-field>

                            <!-- poštna številka -->
                            <v-text-field
                                v-model="postal_code"
                                label="Poštna številka"
                                required
                                :rules="postal_code_rules"
                                type="number"
                                :filled="!existing_permit_holder_fields_to_update.includes('postal_code')"
                                :readonly="!existing_permit_holder_fields_to_update.includes('postal_code')"
                            ></v-text-field>

                            <!-- izbira prebivališča -->
                            <v-select
                            :items="residenceTypes"
                            label="Tip prebivališča"
                            item-text="name"
                            item-value="id"
                            v-model="residence_type"
                            :rules="residence_type_rules"
                            :filled="!existing_permit_holder_fields_to_update.includes('residence_type')"
                            :readonly="!existing_permit_holder_fields_to_update.includes('residence_type')"
                            ></v-select>

                            <!-- emšo
                            <v-text-field
                                v-model="emso"
                                label="EMŠO"
                                required
                                :rules="emso_rules"
                                type="number"
                                filled
                                readonly
                            ></v-text-field>-->

                            <!-- tip osebe (podjetje / fizična oseba) -->
                                <v-select
                            :items="entities"
                            label="Izberi tip osebe"
                            item-text="name"
                            item-value="id"
                            v-model="entity"
                            :rules="entity_rules"
                            :filled="!existing_permit_holder_fields_to_update.includes('entity_type')"
                            :readonly="!existing_permit_holder_fields_to_update.includes('entity_type')"
                            ></v-select>
                            
                            <!-- davčna številka -->
                            <v-text-field
                                v-model="tax_number"
                                label="Davčna številka"
                                required
                                :rules="tax_number_rules"
                                v-if="requireTaxNumber"
                                :filled="!existing_permit_holder_fields_to_update.includes('tax_number')"
                                :readonly="!existing_permit_holder_fields_to_update.includes('tax_number')"
                            ></v-text-field>

                            <div class="div__divider__15"></div>
                            <span class="body-2 font-weight-regular"><v-icon class="" medium color="secondary" left>mdi-information</v-icon> {{ noteText}}</span>
                            <div class="div__divider__15"></div>
                            <v-textarea
                            outlined
                            name="input-7-4"
                            label="Vnesite željeno besedilo (opombe, zahteva za spremembo osebnih podatkov ipd.)"
                            v-model="note"
                            counter
                            required
                            ></v-textarea>
                            <div class="div__divider__15"></div>

                            <!-- telefonska številka
                            <v-text-field
                                v-model="phone_number"
                                label="Telefonska številka"
                                required
                                :rules="telephone_number_rules"
                                type="number"
                            ></v-text-field>-->

                            <!-- registrska številka -->
                            <v-text-field
                                v-model="registration_plate_number_1"
                                label="Registrska številka vozila"
                                :rules="registration_plate_number_rules"
                                required
                            ></v-text-field>

                            <!-- vozilo v osebni lasti ali lasti podjetja -->
                            <v-select
                            :items="vehicleOwnershipTypes"
                            label="Lastništvo vozila"
                            item-text="name"
                            item-value="id"
                            v-model="vehicle_ownership"
                            :rules="vehicle_ownership_rules"
                            ></v-select>

                            <v-divider></v-divider>
                            <br>
                            <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite fotokopijo vašega prometnega dovoljenja. V primeru da je vozilo v lasti podjetja, morate priložiti potrdilo od podjetja. V primeru zamenjave registrske številke za uporabo dovolilnice je potrebno priložiti kopijo prometnega dovoljenja.</p>
                            <p v-if="temporaryResidence" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite fotokopijo dokazila o začasnem prebivališču.</p>
                            <!-- dokumenti -->
                            <VueFileAgent
                            :uploadHeaders="{}"
                            :multiple="true"
                            :deletable="true"
                            :meta="true"
                            :accept="'image/*,.pdf'"
                            :maxSize="'14MB'"
                            :maxFiles="8"
                            :helpText="'Dodaj datoteke...'"
                            :errorText="{
                                type: 'Dovoljen tip datotek - jpg, png, pdf do 3MB',
                                size: 'Dodali ste datoteko, ki presega največjo dovoljeno velikost 3MB!',
                            }"
                            :thumbnailSize="120"
                            :theme="'list'"
                            v-model="files_data"
                            ></VueFileAgent>

                            <br><div style="min-height:5px"></div>
                            <v-divider></v-divider>
                            
                            <!-- način prevzema / dostave  -->

                            <v-checkbox @change="enableSubmission" class="" v-model="disclaimer" :label="disclaimer_text" :rules="disclaimer_rules"></v-checkbox>
                            <div style="min-height:5px"></div>
                            <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon>Vpisani osebni podatki bodo prvič poslani v obdelavo šele v koraku "Oddaj vlogo". Več o obdelavi in hranjenju osebnih podatkov si lahko preberete s klikom <a @click="infoGdpr()">TUKAJ</a>.</p>

                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                            <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="loading || submitted"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                            <v-btn @click="clearForm()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-text>
                        <v-card-actions v-else>
                            <v-btn @click="submitForm()" color="success" text :disabled="loading || submitted"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                            <v-btn @click="clearForm()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-form>
    </div>
</template>

<script>
import imgCardParking from '@/assets/card_blejska_kartica.png'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
import PermitHolderService from '@/services/permit.holder.service.js'
const InfoUserTypeGost = () => import('@/components/InfoUserTypeGost.vue')
const InfoUserTypeDomacin = () => import('@/components/InfoUserTypeDomacin.vue')
const InfoUserTypeVelikaZaka = () => import('@/components/InfoUserTypeVelikaZaka.vue')

export default {
    name: 'replace-parking-permit',
    props: ['uuid', 'permitId', 'permit'],
    components: {
        FormValidationMessages,
        FormSubmitMessages,
        InfoUserTypeGost,
        InfoUserTypeDomacin,
        InfoUserTypeVelikaZaka
    },

    data: () => ({
        submitted:false,
        existing_permit_holder_fields_to_update: [],
        valid: true,
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        residence_type: null,
        temporary_residence_address: null,
        //telephone_number: null,
        tax_number: null,
        subject_type: null,
        //phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        vehicle_ownership: null,
        noteText: "Vnesite opombe ipd.",
        note: null,
        delivery_type: null,
        disclaimer: false,
        disclaimer_text: 'S potrditvijo vloge izjavljam, da so vsi podatki, ki sem jih navedel v vlogi, resnični, točni in popolni in da za svojo izjavo prevzemam vso materialno in kazensko odgovornost. V skladu z določbo 45. člena Odloka organ odvzame pravico do dovolilnice če se izkaže, da podatki v vlogi niso resnični oziroma, da upravičenec več ne izpolnjuje pogojev za dovolilnico.',
        enable_submission: false,
        files_data: [],

        userTypes: [],
        residenceTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        disclaimer_rules: [v => !!v || 'Strinjati se morate s pogoji'],
        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],
        loading:false
    }),

    watch: {
        registration_plate_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_1 = this.registration_plate_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_1 = this.registration_plate_number_1.toUpperCase();
            }
        }
    },

    computed: {
        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        temporaryResidence() {
            if(this.residence_type == "045189aa-656c-4284-8e8d-b91611e896bf") {
                return true
            }

            return false
        },

        imgParking() {
            return imgCardParking
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        infoGdpr() {
            this.$store.commit('SET_INFO_GDPR', true)
        },

        enableSubmission() {
            if(this.disclaimer) {
                this.enable_submission = true;
            }

            this.enable_submission = false;
        },

        getResources() {
            var vm = this;
            if(vm.$store.getters.userTypes !== null) {
                vm.$store.getters.userTypes.forEach(element => {
                    if(element.hasOwnProperty('type')) {
                        if(element.type == 1 || element.type == 2 || element.type == 23) {
                            vm.userTypes.push(element)
                        }
                    }
                })
            }
            
            vm.residenceTypes = vm.$store.getters.residenceTypes
            vm.entities = vm.$store.getters.entities
            vm.vehicleOwnershipTypes = vm.$store.getters.vehicleOwnershipTypes
            vm.permitStatuses = vm.$store.getters.permitStatuses

        },

        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            let vm = this
            if(this.$refs.form.validate()) {
                /*let one  = 1;
                if(1 == one){*/
                vm.$store.commit('SET_LOADER_TEXT', 'Oddaja vloge je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = new FormData();
                payload.append('permit_holder_uuid', vm.permit.permit_holder_uuid)
                payload.append('permit_uuid', vm.permit.uuid)
                payload.append('replacement', 1)
                payload.append('user_type', vm.user_type)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('residence_type', vm.residence_type)
                payload.append('note', vm.note)
                //payload.append('phone_number', vm.phone_number)
                payload.append('emso', vm.emso)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('vehicle_ownership', vm.vehicle_ownership)  

                let c = 1;
                vm.files_data.map(file => {
                    payload.append('file_' + c, file.file)
                    c++
                });
                
                //window.console.log(payload)
                vm.loading = true
                axios({
                    method: 'POST',
                    url: API_ENDPOINT + 'v1/user/applications/parking/create',
                    data: payload,
                    headers: {
                        'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                        'Accept': 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //window.console.log(response)
                    //vm.clearForm()
                    vm.submitted = true
                    vm.existing_permit_holder_fields_to_update = []
                    vm.backendMessages.push('Vloga je bila uspešno oddana. Vaša vloga bo obravana v najkrajšem možnem času. O nadaljnjem poteku v zvezi z izdajo dovolilnice vas bomo obvestili.')
                        vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                })
                .catch(error => {
                    window.console.log(error)
                    window.console.log(error.response)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)

                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })

                            vm.$vuetify.goTo('#form_card', {
                                duration: 1000,
                                offset: 0,
                                easing: 'easeInOutCubic'
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... pri pošiljanju vloge je prišlo do napake. Poskusite ponovno.')
                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }

                })
                .finally(() => {
                    setTimeout(() => {
                        vm.$store.commit('SET_LOADER', false)
                        vm.$store.commit('SET_LOADER_TEXT', '')
                    }, 600)
                    vm.loading = false
                    //window.console.log("finally! :)");
                })
            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                window.console.log("Validation ERROR");
            }
            
        },

        clearForm() {
            //this.$refs.form.reset();
            let vm = this
            
            this.$store.commit('SET_LOADER_TEXT', 'Pridobivanje podatkov o dovolilnici je v teku...')
            this.$store.commit('SET_LOADER', true)

            EventBus.$emit('USER_GET_PERMIT_DATA', vm.uuid)
            EventBus.$on('USER_REFRESH_PERMIT_DATA', function(data) {
                vm.$store.commit('SET_LOADER', false)
                if(data.success != true) {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Pri osveževanju podatkov dovolilnice je prišlo do napake. Prosimo poskusite ponovno.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                } else {
                    vm.permitId = vm.permit.id;
                    vm.user_type = (vm.permit.entity_type != null) ? vm.permit.user_type.uuid : null;
                    vm.name = vm.permit.name
                    vm.address = vm.permit.address
                    vm.town = vm.permit.town
                    vm.postal_code = vm.permit.postal_code
                    vm.residence_type = (vm.permit.residence_type != null) ? vm.permit.residence_type.uuid : null
                    //vm.phone_number = vm.permit.phone_number
                    vm.emso = vm.permit.emso
                    vm.tax_number = vm.permit.tax_number
                    vm.entity = (vm.permit.entity_type != null ) ?  vm.permit.entity_type.uuid : null,
                    vm.permit_type = (vm.permit.permit_type != null) ?  vm.permit.permit_type.uuid : null
                    vm.vehicle_ownership = vm.permit.vehicle_ownership
                    vm.permit_type = vm.permit.permit_type.uuid
                    vm.disclaimer = false

                    vm.files_data = [];
                    vm.backendValidationMessages = []
                    vm.backendMessages = []
                    vm.backendErrorMessages = []

                    this.existing_permit_holder_fields_to_update = []
                    this.existing_permit_holder_fields_to_update = PermitHolderService.fieldsToUpdate(vm.permit)

                    vm.$refs.form.resetValidation();
                    setTimeout(() =>{
                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }, 200)
                }
            })

            vm.submitted = false
        },
    },

    created() {
        this.getResources()
    },

    mounted() {
        //this.getResources()
        var vm = this
        vm.permitId = vm.permit.id;
        vm.user_type = (vm.permit.user_type != null) ? vm.permit.user_type.uuid : null;
        vm.name = vm.permit.name
        vm.address = vm.permit.address
        vm.town = vm.permit.town
        vm.postal_code = vm.permit.postal_code
        vm.residence_type = (vm.permit.residence_type != null) ? vm.permit.residence_type.uuid : null
        //vm.phone_number = vm.permit.phone_number
        vm.emso = vm.permit.emso
        vm.tax_number = vm.permit.tax_number
        vm.entity = (vm.permit.entity_type != null ) ?  vm.permit.entity_type.uuid : null,
        vm.registration_plate_number_1 = vm.permit.registration_plate_number_1
        vm.vehicle_ownership = vm.permit.vehicle_ownership
        vm.permit_type = (vm.permit.permit_type != null) ?  vm.permit.permit_type.uuid : null

        this.existing_permit_holder_fields_to_update = PermitHolderService.fieldsToUpdate(this.permit)
    },

    destroyed() {
        EventBus.$off('USER_REFRESH_PERMIT_DATA')
    }
}

</script>

<style scoped>
</style>